import {
  getAuth,
  verifyPasswordResetCode,
  confirmPasswordReset,
  applyActionCode,
} from "firebase/auth"
import { navigate } from "gatsby"
import { isBrowser } from "services/general"
import { getFirebase } from "../../../services/firebase/firebase"

export const clearFailedLoginCounter = (email) => {
  if (isBrowser()) {
    localStorage.removeItem(btoa(email))
  }
}

export const handleVerifyPasswordResetCode = async ({ actionCode }) => {
  try {
    const auth = getAuth(getFirebase())
    const email = await verifyPasswordResetCode(auth, actionCode)
    clearFailedLoginCounter(email)
  } catch (error) {
    navigate("/auth/error", {
      state: {
        seoTitle: "Invalid Code",
        title: "Request for a new reset link",
        message:
          "Your request to reset your password has expired or the link has already been used.",
      },
    })
  }
}

export const handleResetPassword = async ({
  actionCode,
  successUrl,
  errorCallback,
  newPassword,
}) => {
  try {
    const auth = getAuth(getFirebase())
    await confirmPasswordReset(auth, actionCode, newPassword)
    navigate("/auth/success", {
      state: {
        seoTitle: "Password Reset Successful",
        title: "Your password has been reset",
        message: "You can now sign in with your new password.",
        successUrl,
      },
    })
  } catch (error) {
    if (errorCallback) errorCallback(error)
  }
}

export const handleVerifyEmail = async ({ actionCode, successUrl }) => {
  try {
    const auth = getAuth(getFirebase())
    await applyActionCode(auth, actionCode)
    navigate("/auth/success", {
      state: {
        seoTitle: "Email Verification Successful",
        title: "Your email has been verified",
        message: "You can now sign in with your new account.",
        successUrl,
      },
    })
  } catch (error) {
    navigate("/auth/error", {
      state: {
        seoTitle: "Invalid Code",
        title: "Request for a new reset link",
        message:
          "Your request to verify your email has expired or the link has already been used.",
      },
    })
  }
}
